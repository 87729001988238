import "./App.css";
import Home from "./pages/Home";
import {
  AuthenticatedTemplate,
  useMsal,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { loginRequest } from "./auth-config";
import { useEffect, useState } from "react";
import { ConfigUtil } from './config/config-util';

const { REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL, REACT_APP_SSO_REDIRECT_URI, REACT_APP_ENV } = ConfigUtil();

const WrappedView = ((async) => {
  const { instance } = useMsal();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const activeAccount = instance.getActiveAccount();
  useEffect(() => {
    const initialize = async () => {
      await instance.initialize();
    };

    initialize();
  }, []);

  const handleRedirect = () => {
    console.log("Redirecting to login...");
    setIsRedirecting(true);
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create",
      })
      .catch(() => {
        setIsRedirecting(false);
      });
  };

  useEffect(() => {
    console.log("Config: ", ConfigUtil)
    console.log("Active Account:", activeAccount);
    console.log('ENV:::' + JSON.stringify(process.env));
    console.log('env ', process.env.NODE_ENV || 'testing');
    console.log('REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL ', `${REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL}` || 'testurl');
    console.log('REACT_APP_SSO_REDIRECT_URI ', `${REACT_APP_SSO_REDIRECT_URI}` || 'redirecturl');
    console.log('REACT_APP_ENV ', `${REACT_APP_ENV}` || 'env');
    if (!activeAccount && !isRedirecting) {
      console.log("No active account found. Redirecting to login...");
      handleRedirect();
    }
  }, [activeAccount, isRedirecting]);
  return (
    <div className="App">
      {/* <p>
        Active Account:{" "}
        {activeAccount ? activeAccount.username : "No active account"}
      </p> */}

      {activeAccount ? (
        <AuthenticatedTemplate>
          <Home />
        </AuthenticatedTemplate>
      ) : (
        null
      )}
    </div>
  );
});
// }

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <WrappedView />
    </MsalProvider>
  );
};

export default App;
