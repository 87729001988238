import * as React from 'react';
import { AuthenticationContext, SessionContext } from '@toolpad/core/AppProvider';
import { Account } from '@toolpad/core/Account';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../../auth-config';


// const demoSession = {
//   user: {
//     name: 'Sridharan',
//     email: 'sc3008@7-11.com'
//   },
// };

export default function AccountDemoSignedIn() {
  const msalInstance = new PublicClientApplication(msalConfig);
  const userDetails = {
    user: {
      name: msalInstance.getActiveAccount().name,
      email: msalInstance.getActiveAccount().username
    }
  }
  const [session, setSession] = React.useState(userDetails);

  const authentication = React.useMemo(() => {
    return {
      signIn: () => {
        setSession(userDetails);
      },
      signOut: () => {
        // ClassNames = "sign-out"
        // setSession(demoSession);
      },
    };
  }, []);

  return (
    <AuthenticationContext.Provider value={authentication}>
      <SessionContext.Provider value={session}>
        {/* preview-start */}
        <Account />
        {/* preview-end */}
      </SessionContext.Provider>
    </AuthenticationContext.Provider>
  );
}
