import React, { useEffect, useState } from "react";
import Center from "../shared/Components/Center";
import Header from "../shared/Components/Header";
import "../shared/assets/css/Home.css";
import bulk from "../shared/assets/svg/bulk-logo.svg";
import rack from "../shared/assets/svg/rack-logo.svg";
import { useMsal } from "@azure/msal-react";
import { getRoleDetails } from "../services/AuthorizationServices";
import { FuelSupply } from "../constants/InitialState";
// import { ConfigUtil } from '../config/config-util';

// const { REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL } = ConfigUtil;

function Home() {
  const { instance } = useMsal();
  const [getFuelsData, setGetFuelsData] = useState([]);
  const activeAccount = instance.getActiveAccount();
  const getData = async() => {
   
    if(activeAccount) {
      try {
        const data = await getRoleDetails(activeAccount.idToken);
        console.log('data', data.data.permissions.Fuels);
        setGetFuelsData( data.data.permissions.Fuels);
        // const [attrState, setAttrState] = useState(FuelSupply);
        // localStorage.setItem('getRole', data.data.permissions.Fuels);
        // const getRoleData = getFuelsData.map((item, i) => {
        //   console.log('itemmmmm ' , item.attributeName);
        //   // setAttrState((FuelSupply)=> {
        //   //   rack
        //   // })
         
        // })
 
        // const getFuelData = data.data.permissions.Fuels;
        // console.log('getFuelData', getFuelData['attributeName']);
 
      } catch (error) {
        console.error(`Error fetching data:`, error);
      }
    }
  }
  useEffect(() => {
    getData();
  }, [])
  return (
    <div className="home-container">
      <Header />
      <div>
      <Center>
        <div className="bg-white">
          <h3 className="app-select-heading">Select your application</h3>
          <p className="app-tagline">
            Please choose your application from the following to continue to the
            specific dashboard{" "}
          </p>
          <div>
          
          {getFuelsData.map((attr) => (       
            <div key={attr.attributeName}> 
            <a  href={process.env.REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL + "/osa/"} className={attr.permissionType === 'W' ? (attr.attributeName ==='fsa-bulk-supply-osa' ? 'accept' : 'disabled'): 'dde'}>
              <div className="bulk-supply bg-grey">
                <div className="icon-bg">
                  <img
                    src={bulk}
                    className="logo-supply"
                    alt="logo"
                    width="32"
                  />
                </div>
                <p className="app-name">Bulk Supply (OSA)</p>
              </div>
            </a>
            <a href={process.env.REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL + "/ics/"} className={attr.permissionType === 'W' ? (attr.attributeName ==='fsa-rack-supply-ics' ? 'accept' : 'disabled'): 'ddei'}>
            <div className="rack-supply bg-grey">
              <div className="icon-bg">
                <img
                  src={rack}
                  className="logo-supply"
                  alt="logo"
                  width="32"
                />
              </div>
              <p className="app-name">Rack Supply (ICS)</p>
            </div>
          </a>
        </div>

         ))}


          
                      {/* {attrState.map((attr) => (         <h2 key={attr.attributeName}>{attr.permissionType}</h2> ))} */}
            
          </div>
        </div>
      </Center>
    </div>
    </div>
   
  );
}

export default Home;
