import React from "react";
import UserData from "./UserData";
import logo from "../assets/svg/7-eleven-logo.svg";
import "../assets/css/Header.css";

function Header() {
  return (
    <header className="header">
      <div>
        <img src={logo} className="project-logo" alt="logo" width="27" />
        <span className="project-name">Fuels Supply</span>
      </div>
      <nav>
        <UserData />
      </nav>
    </header>
  );
}

export default Header;
