/**
 * This will read the properties from env.js at runtime and replace them with properties in DefaultConfig
 */

import { DefaultConfig } from './default-config';

export const ConfigUtil = () => {
    const env = DefaultConfig;
    const browserWindow = window || {};
    const browserWindowEnv = browserWindow['_env_'] || {};

    for (const key in browserWindowEnv) {
        if (browserWindowEnv.hasOwnProperty(key)) {
            env[key] = window['_env_'][key];
        }
    }

    return env;
};
