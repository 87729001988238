import axios from "axios";
import { API_ENDPOINTS } from "../constants/ApiEndPoints";

const BASE_URL = "https://fuelssupply.7ep-dev.7-eleven.com";

export const getRoleDetails = async (params) => {
    console.log('params', params);
  try {
    const response = await axios.get(
      `${BASE_URL}${API_ENDPOINTS.GET_BASED_AUTHORIZATION_URL}${API_ENDPOINTS.GET_DEFAULT_ENDPOINTS_URL}${API_ENDPOINTS.GET_AUTHORIZATION_URL}`,
      {
        headers: {
            "TOKEN": params
          },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching dropdown data:", error);
    throw error;
  }
};

